import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { ListHeader } from "./ListHeader";
import { FaEdit } from "react-icons/fa";
import { CREATEPAGEABTEST, UPDATEPAGEABTEST } from "../../../Graphql/Mutations";
import { DeleteUrlIcon } from "../../Shared/Icons";
import Select from "react-select";
import { toast } from "react-toastify";
import { CLIENTTARGETINGPAGES } from "../../../Graphql/Queries";
import { LoaderLogo } from "../../Shared/LoaderLogo";

const PAGE_HEADER = [
  "Name",
  "Trigger",
  "Trigger Js",
  //   "Deactivation Mode",
  //   "Rules Js",
  //   "Poll On Rules",
  //   "Type",
  //   "Url",
  //   "Url Type",
  "Edit/Delete",
];

export const PageTargeting = ({
  type,
  module,
  formType,
  currentData,
  client,
  urlTargeting,
  setModule,
  setCurrentData,
  setFormType,
  setUrlTargeting,
}) => {
  const modes = [
    { value: "direct", label: "Direct (immediately when Codebase runs)" },
    {
      value: "callback",
      label: "Call back (when callback function is called)",
    },
  ];

  const [triggerMode, setTriggerMode] = useState(
    currentData
      ? modes.find((mode) => mode.value === currentData.trigger)
      : modes[0]
  );
  const [createPage] = useMutation(CREATEPAGEABTEST);
  const [updatePage] = useMutation(UPDATEPAGEABTEST);

  const handleChange = (selectedOption, index, type) => {
    const { value } = type === "url" ? selectedOption.target : selectedOption;
    let newUrlTargeting = [...urlTargeting];
    newUrlTargeting[index] = { ...newUrlTargeting[index], [type]: value };
    setUrlTargeting(newUrlTargeting);
  };

  const { data, loading, error, refetch } = useQuery(CLIENTTARGETINGPAGES, {
    variables: {
      filters: [
        {
          client: client,
        },
      ],
    },
  });

  if (loading) return <LoaderLogo />;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <>
      {type === "form" ? (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const { name, trigger, trigger_js, rules_js, poll_on_rules } =
              e.target;

            const filteredUrlTargeting = urlTargeting.map(
              ({ __typename, _id, ...rest }) => rest
            );

            const urltargetings = filteredUrlTargeting;
            const pageData = {
              client: client,
              name: name.value,
              trigger: trigger.value,
              trigger_js: trigger_js.value || "return true;",
              deactivation_mode: "reset",
              rules_js: rules_js.value || "return true;",
              poll_on_rules: poll_on_rules.checked,
              urltargetings: urltargetings,
            };
            if (formType === "add") {
              await createPage({
                variables: {
                  pageData: pageData,
                },
              });
              toast.success("Page Added Successfully");
            } else {
              await updatePage({
                variables: {
                  id: currentData?._id,
                  pageData: pageData,
                },
              });
              toast.success("Page Edited Successfully");
            }
            refetch();
            setModule(null);
          }}
        >
          <div className="fields-wrapper fields-wrapper__updated">
            <div>
              <label>Name</label>
              <input
                required
                name="name"
                className="field-control  field-control__updated"
                placeholder=""
                defaultValue={currentData ? currentData.name : ""}
              />
            </div>
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>Trigger</label>
            <Select
              name="trigger"
              className="acc-newselectwrapper"
              classNamePrefix="acc-newselect"
              options={[
                {
                  value: "direct",
                  label: "Direct (immediately when Codebase runs)",
                },
                {
                  value: "callback",
                  label: "Call back (when callback function is called)",
                },
              ]}
              onChange={(selectedOption) => setTriggerMode(selectedOption)}
              defaultValue={triggerMode}
            />
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>Trigger js ({triggerMode?.value})</label>
            <textarea
              name="trigger_js"
              className="field-control  field-control__updated"
              placeholder={
                triggerMode?.value === "direct" ? `return true;` : `activate()`
              }
              defaultValue={
                currentData ? currentData.trigger_js : "return true;"
              }
            />
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>Rules js</label>
            <textarea
              name="rules_js"
              className="field-control  field-control__updated"
              placeholder=""
              defaultValue={currentData ? currentData.rules_js : "return true;"}
            />
          </div>
          <div className="fields-wrapper inline-checkbox">
            <div className="checkbox-field-wrapper">
              <input
                type="checkbox"
                name="poll_on_rules"
                defaultChecked={currentData?.poll_on_rules ? true : false}
                className="field-control"
              />
            </div>
            <label>Poll on Rules</label>
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>URL Targeting :-</label>
          </div>
          {urlTargeting.map((item, index) => (
            <div className="d-flex" style={{ gap: "10px" }} key={index}>
              <div className="fields-wrapper fields-wrapper__updated">
                <label>Type</label>
                <Select
                  name={`type_${index}`}
                  className="acc-newselectwrapper"
                  classNamePrefix="acc-newselect"
                  required
                  placeholder="Select"
                  options={[
                    { value: "include", label: "Include" },
                    { value: "exclude", label: "Exclude" },
                  ]}
                  defaultValue={
                    item.type ? { value: item.type, label: item.type } : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(selectedOption, index, "type")
                  }
                />
              </div>
              <div className="fields-wrapper fields-wrapper__updated">
                <label>url</label>
                <input
                  name={`url_${index}`}
                  className="field-control  field-control__updated"
                  placeholder=""
                  required
                  defaultValue={item.url}
                  onChange={(e) => handleChange(e, index, "url")}
                />
              </div>
              <div className="fields-wrapper fields-wrapper__updated">
                <label>url Type</label>
                <Select
                  name={`url_type$_${index}`}
                  className="acc-newselectwrapper"
                  classNamePrefix="acc-newselect"
                  placeholder="Select"
                  required
                  options={[
                    { value: "substring", label: "Sub string" },
                    { value: "regex", label: "Regex" },
                    { value: "simple", label: "Simple" },
                  ]}
                  defaultValue={
                    item.url_type
                      ? {
                          value: item.url_type,
                          label: item.url_type,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleChange(selectedOption, index, "url_type")
                  }
                />
              </div>
              <button
                className="mt-4 w-25 h-25 btn text-white"
                onClick={() => {
                  const arr = [...urlTargeting];
                  arr.splice(index, 1);
                  setUrlTargeting(arr);
                }}
              >
                <DeleteUrlIcon />
              </button>
            </div>
          ))}
          <button
            className="btn border text-white"
            onClick={() => {
              const arr = [...urlTargeting];
              arr.push({
                type: "",
                url: "",
                url_type: "",
              });
              setUrlTargeting(arr);
            }}
          >
            Add Url Targeting
          </button>
          <div className="add__new__test__form-footer">
            <button className="btn next-btn" type="submit">
              {formType === "add" ? `Add ${module}` : `Edit ${module}`}
            </button>
          </div>
        </form>
      ) : (
        <>
          <ListHeader header={PAGE_HEADER} />
          <div>
            {data?.pages?.pageData?.map((row, idx) => (
              <div
                key={`${row.name}-${idx}`}
                className="test-details-table-data"
              >
                <div className="test-name text-break">
                  <h3>{row?.name}</h3>
                </div>
                <div className="test-name text-break">
                  <h3>{row?.trigger}</h3>
                </div>
                <div className="test-name text-break">
                  <h3>{row?.trigger_js}</h3>
                </div>
                {/* <div className="test-name text-break">
              <h3>{row?.deactivation_mode}</h3>
            </div>
            <div className="test-name text-break">
              <h3>{row?.rules_js}</h3>
            </div>
            <div className="test-name text-break">
              <h3>{row?.poll_on_rules ? "true" : "false"}</h3>
            </div>
            {row?.urltargetings?.map((row) => (
              <>
                <div className="test-name text-break">
                  <h3>{row?.type}</h3>
                </div>
                <div className="test-name text-break">
                  <h3>{row?.url}</h3>
                </div>
                <div className="test-name text-break">
                  <h3>{row?.url_type}</h3>
                </div>
              </>
            ))} */}
                <div className="test-name text-break">
                  <FaEdit
                    onClick={() => {
                      setModule("Page Targeting");
                      setCurrentData(row);
                      setFormType("edit");
                      setUrlTargeting(row?.urltargetings);
                    }}
                    style={{ cursor: "pointer", color: "gray" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
