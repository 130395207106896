import { VscArrowSmallLeft, VscArrowSmallRight } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";

export const CustomNavigator = ({ disableBackNavigate }) => {
  const navigate = useNavigate();
  return (
    <nav className="custom-navigator">
      <ul>
        <li className="">
          <button
            type="button"
            disabled={disableBackNavigate ?? false}
            onClick={(_) => navigate(-1)}
            title="Click to go back"
          >
            <VscArrowSmallLeft />
          </button>
        </li>
        <li className="">
          <button
            type="button"
            onClick={(_) => navigate(1)}
            title="Click to go forward"
          >
            <VscArrowSmallRight />
          </button>
        </li>
      </ul>
    </nav>
  );
};
