export const ListHeader = ({ header }) => {
  return (
    <div className="test-details-table-data heading-row">
      {header.map((elm, idx) => (
        <div key={`${elm}-${idx}`} className="test-name">
          <h3>{elm}</h3>
        </div>
      ))}
    </div>
  );
};
