import { useEffect, useMemo } from "react";
import { isNumber } from "lodash";
import { toast } from "react-toastify";

export const AddManualReportData = ({
  variantOptions,
  manualData,
  setManualData,
}) => {
  const variantValuesArr = useMemo(() => {
    return variantOptions?.map((option) => {
      const data = manualData.find((d) => d.audienceName === option.label);
      return {
        audienceName: option.label,
        totalUsers: data?.users ?? 0,
        sessions: data?.sessions ?? 0,
        transactions: data?.transactions ?? 0,
        purchasers: data?.purchasers ?? 0,
        purchaseRevenue: data?.revenue ?? 0,
        stddev_rpu: data?.stddev_rpu ?? 0,
        stddev_aov: data?.stddev_aov ?? 0,
      };
    });
  }, [variantOptions]);

  const setValues = (e, variantkey, key) => {
    if (isNaN(Number(e.target.value))) {
      toast.error("Please enter a number");
    }
    const updatedData = [...manualData];
    const index = updatedData.findIndex(
      (data) => data.audienceName === variantkey
    );
    if (index !== -1) {
      updatedData[index][key] = Number(e.target.value);
      setManualData(updatedData);
    } else {
      const newVariant = {
        audienceName: variantkey,
        totalUsers: 0,
        sessions: 0,
        transactions: 0,
        purchasers: 0,
        purchaseRevenue: 0,
        stddev_rpu: 0,
        stddev_aov: 0,
      };
      newVariant[key] = Number(e.target.value);
      setManualData([...manualData, newVariant]);
    }
  };
  return (
    <div className="manual-data-form-wrapper">
      {variantValuesArr?.map((option, index) => {
        return (
          <section key={option.audienceName}>
            <label>{option.audienceName}</label>
            <div className="fields-wrapper input-groups">
              <div>
                <label>Users</label>
                <input
                  type="text"
                  placeholder="Users"
                  name="totalUsers"
                  defaultValue={option.totalUsers}
                  onChange={(e) =>
                    setValues(e, option.audienceName, "totalUsers")
                  }
                />
              </div>
              <div>
                <label>Sessions</label>
                <input
                  type="text"
                  placeholder="Sessions"
                  name="sessions"
                  defaultValue={option.sessions}
                  onChange={(e) =>
                    setValues(e, option.audienceName, "sessions")
                  }
                />
              </div>
              <div>
                <label>Purchasers</label>
                <input
                  type="text"
                  placeholder="Transactions"
                  name="purchasers"
                  defaultValue={option.purchasers}
                  onChange={(e) =>
                    setValues(e, option.audienceName, "purchasers")
                  }
                />
              </div>
              <div>
                <label>Transactions</label>
                <input
                  type="text"
                  placeholder="Transactions"
                  name="transactions"
                  defaultValue={option.transactions}
                  onChange={(e) =>
                    setValues(e, option.audienceName, "transactions")
                  }
                />
              </div>
              <div>
                <label>RPU Std</label>
                <input
                  type="text"
                  placeholder="RPU Std"
                  name="stddev_rpu"
                  defaultValue={option.stddev_rpu}
                  onChange={(e) =>
                    setValues(e, option.audienceName, "stddev_rpu")
                  }
                />
              </div>
              <div>
                <label>AOV Std</label>
                <input
                  type="text"
                  placeholder="AOV Std"
                  name="stddev_aov"
                  defaultValue={option.stddev_aov}
                  onChange={(e) =>
                    setValues(e, option.audienceName, "stddev_aov")
                  }
                />
              </div>
              <div>
                <label>Revenue</label>
                <input
                  type="text"
                  placeholder="Revenue"
                  name="purchaseRevenue"
                  defaultValue={option.purchaseRevenue}
                  onChange={(e) =>
                    setValues(e, option.audienceName, "purchaseRevenue")
                  }
                />
              </div>
            </div>
          </section>
        );
      })}
      {/* <div className="add-manu-data-button">
        <button type="button" className="btn btn-sm btn-primary">
          Add Data
        </button>
      </div> */}
    </div>
  );
};
