// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.variant-performance-block {
  margin-bottom: var(--default-margin-bottom);
}

.variant-performance-inner {
  row-gap: var(--default-row-gap);
}`, "",{"version":3,"sources":["webpack://./src/Components/NewComponents/VariantPerformance/VariantPerformance.scss"],"names":[],"mappings":"AAAA;EACI,2CAAA;AACJ;;AACA;EACI,+BAAA;AAEJ","sourcesContent":[".variant-performance-block{\n    margin-bottom: var(--default-margin-bottom);\n}\n.variant-performance-inner{\n    row-gap: var(--default-row-gap);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
