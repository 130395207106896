// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding ul li h3 {
  font-weight: var(--font-600);
}
.onboarding.overview ul li {
  flex: 1 1;
}
.onboarding.overview .count {
  color: var(--desc-color-lite);
  font-weight: var(--font-600);
  font-size: var(--value24);
}`, "",{"version":3,"sources":["webpack://./src/Components/NewComponents/AbTestToolOnBoarding/AbTestToolOnBoarding.scss"],"names":[],"mappings":"AAGQ;EACI,4BAAA;AAFZ;AAQQ;EACI,SAAA;AANZ;AASI;EACI,6BAAA;EACA,4BAAA;EACA,yBAAA;AAPR","sourcesContent":[".onboarding{\n ul {\n    li {\n        h3{\n            font-weight: var(--font-600);\n        }\n    }\n }\n &.overview{\n    ul{\n        li{\n            flex: 1 1 0;\n        }\n    }\n    .count{\n        color: var(--desc-color-lite);\n        font-weight: var(--font-600);\n        font-size: var(--value24);\n    }\n }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
