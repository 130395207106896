import React, { useState } from "react";
import "./VariantPerformance.scss";
import ReportCard from "../ReportCard/ReportCard";
import { Button } from "../../MiroComponents/Button/Button";
import { FilterIcon } from "../../Shared/Icons";
const revenueBaseValues = ["CR", "AOV", "RPU"];
export default function VariantPerformance({
  calculatedResults = [],
  currency = "",
  isMabTest = false,
}) {
  const [revenueBase, setRevenueBase] = useState("CR");
  if (calculatedResults?.length === 0) return null;
  return (
    <div className="variant-performance-wrapper section-pd border-bottom">
      <h1>Variant Performance</h1>

      <div
        className="results-filter-wrapper flex align-center justify-space-between flex-wrap"
        style={{
          paddingLeft: "0px",
        }}
      >
        <div className="flex align-center">
          <div
            className="inline-flex align-center"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <span>
              <FilterIcon />
            </span>
            <span>Additional Revenue with:</span>{" "}
          </div>
          <div className="inline-flex">
            {revenueBaseValues.map((value, index) => (
              <Button
                key={index}
                text={`${value}`}
                onClick={(e) => {
                  setRevenueBase(value);
                }}
                className={`smart-button small ${
                  revenueBase === value ? `active neon-shadow` : ``
                }`}
              />
            ))}
          </div>
        </div>
      </div>

      {calculatedResults.map((result, index) => (
        <div className="variant-performance-block" key={index}>
          <h2>{result?.audienceName} </h2>
          <div className="variant-performance-inner flex flex-wrap justify-space-between">
            <ReportCard
              type="variantperformance"
              data={{
                title: "Addtional Revenue (During Runtime)",
                value: result?.additionalRevenueDuringNow,
                tooltip: "Addtional Revenue",
                prefix: currency,
                postfix: null,
                formatColor: {
                  threshold: 0,
                },
              }}
            />
            {!isMabTest && (
              <>
                <ReportCard
                  type="variantperformance"
                  data={{
                    title: "Change in CR",
                    value: result?.changeInConversionRate,
                    prefix: "",
                    postfix: "%",
                    formatColor: {
                      threshold: 0,
                    },
                  }}
                />
                <ReportCard
                  type="variantperformance"
                  data={{
                    title: `Confidence of ${result?.confidenceOf}`,
                    value: result?.confidenceOfSelection,
                    prefix: "",
                    postfix: "%",
                    formatColor: {
                      // threshold: 0,
                    },
                  }}
                />
                <ReportCard
                  type="variantperformance"
                  data={{
                    title: "Change In RPU (Absolute)",
                    value: result?.change_absoluteRPU,
                    prefix: "",
                    postfix: "",
                    formatColor: {
                      threshold: 0,
                    },
                  }}
                />
                <ReportCard
                  type="variantperformance"
                  data={{
                    title: "Change In RPU (Relative)",
                    value: result?.change_relativeRPU,
                    prefix: "",
                    postfix: "%",
                    formatColor: {
                      threshold: 0,
                    },
                  }}
                />
                <ReportCard
                  type="variantperformance"
                  data={{
                    title: "Confidence RPU",
                    value: result?.significanceRPU,
                    prefix: "",
                    postfix: "%",
                    formatColor: {
                      // threshold: 0,
                    },
                  }}
                />
                <ReportCard
                  type="variantperformance"
                  data={{
                    title: "Change in AOV (Absolute)",
                    value: result?.change_absoluteAOV,
                    prefix: "",
                    postfix: "",
                    formatColor: {
                      // threshold: 0,
                    },
                  }}
                />

                <ReportCard
                  type="variantperformance"
                  data={{
                    title: "Change in AOV (Relative)",
                    value: result?.change_relativeAOV,
                    prefix: "",
                    postfix: "%",
                    formatColor: {
                      threshold: 0,
                    },
                  }}
                />
                <ReportCard
                  type="variantperformance"
                  data={{
                    title: "Confidence AOV",
                    value: result?.significanceAOV,
                    prefix: "",
                    postfix: "%",
                    formatColor: {
                      threshold: 0,
                    },
                  }}
                />
                <ReportCard
                  type="variantperformance"
                  data={{
                    title: "Additional Transactions Per Month",
                    value: result?.additionalTransactionsPerMonth,
                    prefix: "",
                    postfix: "",
                    formatColor: {
                      threshold: 0,
                    },
                  }}
                />

                <ReportCard
                  type="variantperformance"
                  data={{
                    title: "Additional Revenue Per Month",
                    value:
                      revenueBase === "CR"
                        ? result?.additionalRevenuePerMonth
                        : result[revenueBase]?.additionalRevenuePerMonth,
                    prefix: "",
                    postfix: "",
                    formatColor: {
                      threshold: 0,
                    },
                  }}
                />

                <ReportCard
                  type="variantperformance"
                  data={{
                    title: "Additional Revenue Per Year",
                    value:
                      revenueBase === "CR"
                        ? result?.additionalRevenuePerYear
                        : result[revenueBase]?.additionalRevenuePerYear,
                    prefix: "",
                    postfix: "",
                    formatColor: {
                      threshold: 0,
                    },
                  }}
                />
              </>
            )}
          </div>
        </div>
      ))}

      {/* <div className="variant-performance-block section-pd-top">
        <h2>Results can be evaluated in: 14 days </h2>
      </div> */}
    </div>
  );
}
