import React, { useState } from "react";
import { AudienceTargeting } from "./AudienceTargeting";
import { PageTargeting } from "./PageTargeting";
import { GoalsTargeting } from "./GoalsTargeting";
import { EnvironmentTargeting } from "./EnvironmentTargeting";

const TARGETING_MODULES = [
  // "Audience Targeting",
  // "Page Targeting",
  "Goals",
  // "Environment",
];
export default function TargetingModuleList({
  client,
  activeTab,
  setActiveTab,
}) {
  const [module, setModule] = useState(null);
  const [formType, setFormType] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [urlTargeting, setUrlTargeting] = useState([
    {
      type: "",
      url: "",
      url_type: "",
    },
  ]);

  return (
    <div>
      {module && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner form-wrapper-inner__updated addtestform  scroll">
            <div
              className="card add-new-test-card scroll modal-card"
              style={{ minHeight: "fit-content" }}
            >
              <button
                className="close-modal"
                onClick={(_) => {
                  setModule(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <h3>{formType === "add" ? `Add ${module}` : `Edit ${module}`}</h3>
              <div className="card-body p-0">
                {module === "Audience Targeting" && (
                  <AudienceTargeting
                    type="form"
                    formType={formType}
                    module={module}
                    client={client}
                    currentData={currentData}
                    setCurrentData={setCurrentData}
                    setModule={setModule}
                    setFormType={setFormType}
                  />
                )}
                {module === "Page Targeting" && (
                  <PageTargeting
                    type="form"
                    formType={formType}
                    module={module}
                    client={client}
                    currentData={currentData}
                    urlTargeting={urlTargeting}
                    setModule={setModule}
                    setCurrentData={setCurrentData}
                    setFormType={setFormType}
                    setUrlTargeting={setUrlTargeting}
                  />
                )}
                {module === "Goals" && (
                  <GoalsTargeting
                    type="form"
                    formType={formType}
                    module={module}
                    client={client}
                    currentData={currentData}
                    setModule={setModule}
                    setCurrentData={setCurrentData}
                    setFormType={setFormType}
                  />
                )}
                {module === "Environment" && (
                  <EnvironmentTargeting
                    type="form"
                    formType={formType}
                    module={module}
                    client={client}
                    currentData={currentData}
                    setModule={setModule}
                    setCurrentData={setCurrentData}
                    setFormType={setFormType}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {TARGETING_MODULES?.map((moduleName) => (
        <section key={moduleName} className="ab-test-overview">
          <div className="experiment-list-inner">
            <div className="experiment-box">
              <div
                className="experiment-box-head"
                onClick={(e) => {
                  e.target
                    .closest(".experiment-box")
                    .querySelector(".experiment-overview")
                    .classList.toggle("active");
                }}
              >
                <h3>{moduleName}</h3>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setModule(moduleName);
                    setCurrentData(null);
                    setFormType("add");
                    setUrlTargeting([{ type: "", url: "", url_type: "" }]);
                  }}
                  className="btn border text-white"
                >
                  {`Add ${moduleName}`}
                </button>
              </div>
              <div
                className="experiment-overview p-0 active"
                id="add-new-test-form"
              >
                <div className="py-4 px-2">
                  {moduleName === "Audience Targeting" && (
                    <AudienceTargeting
                      client={client}
                      formType={formType}
                      module={module}
                      currentData={currentData}
                      setCurrentData={setCurrentData}
                      setModule={setModule}
                      setFormType={setFormType}
                    />
                  )}
                  {moduleName === "Page Targeting" && (
                    <PageTargeting
                      formType={formType}
                      module={module}
                      client={client}
                      currentData={currentData}
                      urlTargeting={urlTargeting}
                      setModule={setModule}
                      setCurrentData={setCurrentData}
                      setFormType={setFormType}
                      setUrlTargeting={setUrlTargeting}
                    />
                  )}
                  {moduleName === "Goals" && (
                    <GoalsTargeting
                      client={client}
                      formType={formType}
                      module={module}
                      currentData={currentData}
                      setModule={setModule}
                      setCurrentData={setCurrentData}
                      setFormType={setFormType}
                    />
                  )}
                  {moduleName === "Environment" && (
                    <EnvironmentTargeting
                      client={client}
                      formType={formType}
                      module={module}
                      currentData={currentData}
                      setModule={setModule}
                      setCurrentData={setCurrentData}
                      setFormType={setFormType}
                    />
                  )}
                </div>
                {/* <div className="experiment-box-footer">
                  <div></div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}
