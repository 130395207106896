import React, { Suspense, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { GETADDREVENUE } from "../../../Graphql/QueriesNew";
import LineChartComponent from "../LineChartComponent/LineChartComponent";
import ContentSkeleton from "../Skeletons/ContentSkeleton";

export default function AddRevPerMonth({ client, store, theme }) {
  const [addrev] = useLazyQuery(GETADDREVENUE);
  const [data, setData] = useState({
    loading: true,
    data: null,
  });
  useEffect(() => {
    (async () => {
      try {
        const { data, loading } = await addrev({
          fetchPolicy: "network-only",
          variables: {
            client: client,
            dateRange: {
              from: moment().subtract(1, "year").startOf("day").toDate(),
              to: moment().endOf("day").toDate(),
            },
          },
        });
        setData({
          ...data,
          loading,
        });
      } catch (error) {}
    })();
  }, [client, store, addrev]);

  if (data?.loading || !data?.getrevenueV2)
    return (
      <div>
        <ContentSkeleton />
      </div>
    );
  return (
    <>
      {/* {console.log(data)} */}
      <Suspense fallback={<ContentSkeleton />}>
        <LineChartComponent
          title="Additional Revenue Per Month"
          data={{
            labels: data?.getrevenueV2?.data?.map((d) => [d.month, d.year]),
            datasets: [
              {
                data: [...data?.getrevenueV2?.data?.map((d) => d.revenue), 0],
                fill: true,
                borderColor: "#00FF8D",
                tension: 0.5,
                lineTension: 0.4,
                label: "Add. revenue per month",
                borderWidth: 1,
                pointRadius: 2,
                pointHoverRadius: 3,
                pointBorderColor: theme === "light-theme" ? "#000" : "#fff",
                pointBackgroundColor: theme === "light-theme" ? "#000" : "#fff",
                backgroundColor: (context) => {
                  const ctx = context.chart.ctx;
                  const gradient = ctx.createLinearGradient(10, 10, 10, 400);
                  gradient.addColorStop(0, "rgba(0, 255, 141, 0.28)");
                  gradient.addColorStop(
                    1,
                    theme === "light-theme" ? "#fff" : "#00000057"
                  );
                  return gradient;
                },
              },
            ],
          }}
        />
      </Suspense>
    </>
  );
}
