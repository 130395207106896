// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.target-options > li {
  max-width: 32.49%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewComponents/TargetDefination/TargetDefination.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,WAAA;AAAR","sourcesContent":[".target-options{\n    >li{\n        max-width: 32.49%;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
